@mixin component-accordion {
	.accordion__body {
		overflow: hidden;
		padding: 70px 0;
		.row {
			position: relative;
		}
	}
	.accordion__header {
		border-bottom: 1px solid $gray-brand;
		.btn {
			position: relative;
			font-size: 14px;
			color: #000;
			
			&:hover,
			&[aria-expanded="true"] {
				text-decoration: none;
				&:after {
					content: '';
					display: block;
					background: #000;
					width: 100%;
					height: 2px;
					position: absolute;
					left: 0;
					bottom: -2px;
				}
			}
			&[aria-expanded="false"] h4 {
				color: $gray-brand;
			}
		}
	}
	.accordion__item-heading {
		position: absolute;
		top: -70px;
		left: 50%;
		transform: translateX(-50%);
		font-family: $heading-second;
		font-size: 55px;
		line-height: 120px;
		color: #ddd;
		text-transform: uppercase;
		opacity: 0.7;
		z-index: -1;
		@include breakpoint(small) {
			font-size: 120px;
			top: -50px;
		}
		@include breakpoint(large) {
			font-size: 155px;
		}
	}

	.accordion__item-img {
		margin-bottom: 20px;
		@include breakpoint(small) {
			margin-bottom: 0;
		}
	}
}